import React from "react";
import { getTYM } from "../../../utils";
import {
  useDashboardData,
  useSiteQuery,
} from "../../../utils/react-query-hooks";
import { Spinner } from "../../UI";
import { NumberCounter } from "../NumberCounter";
import "./DetailsContainer.scss";

interface DetailsContainerProps {
  heading: string;
  inverter: string;
  site: string;
}

export const DetailsContainer: React.FC<DetailsContainerProps> = ({
  heading,
  site,
  inverter,
}) => {
  // const site = 'ukkadam'
  // const inverter = 'UKF1MW'
  const { data: siteData } = useSiteQuery();
  siteData?.find((v) => v.name === site);

  const [today, yesterday, month] = getTYM();

  const todayCurrent = useDashboardData([site, inverter, today]);

  const yesterdayCurrent = useDashboardData([site, inverter, yesterday]);

  const monthCurrent = useDashboardData([site, inverter, month], true, true);

  const isLoading =
    todayCurrent.isLoading ||
    yesterdayCurrent.isLoading ||
    monthCurrent.isLoading;

  if (isLoading) {
    return <Spinner />;
  }

  const data = [
    { title: "today", value: todayCurrent.data ? todayCurrent.data : 0 },
    {
      title: "yesterday",
      value: yesterdayCurrent.data ? yesterdayCurrent.data : 0,
    },
    { title: "this Month", value: monthCurrent.data ? monthCurrent.data : 0 },
  ];

  return (
    <div className="details-container">
      <div className="heading">{heading}</div>
      <div className="content">
        {data.map((val, key) => (
          <UnitAndTitle data={val} key={key} />
        ))}
      </div>
    </div>
  );
};

const UnitAndTitle = ({ data = { title: "", value: 0 } }) => {
  // const newValue = data.value >= 1000 ? data.value / 1000 : data.value;
  // const unit = data.value >= 1000 ? "mW" : "kW";


  return (
    <div className="unit-and-title">
      <NumberCounter
        from={0}
        to={data.value}
        className="unit-value"
        duration={1}
        unit={"units"}
      />
      <div className="unit-title">{data.title}</div>
    </div>
  );
};
