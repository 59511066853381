import React from 'react'
import { Modal } from '../Modal'
import './Spinner.scss'

export const Spinner: React.FC = () => {
  return (
    <Modal onClick={() => {}}>
      <div className="spinner"></div>
    </Modal>
  )
}
