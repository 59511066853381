import React from "react";
import "./DetailsShowCase.scss";

export const DetailsShowCase = ({
  heading = "",
  obj = [{ name: "", value: "" }],
  color = "black",
  customStyle = {},
  customClass = "",
  hide = false
}) => {
  if (hide){
    return <></>
  }
  return (
    <div
      className={`details-showcase ${customClass}`}
      style={{ border: `.1rem solid ${color}`, ...customStyle }}
    >
      <div
        className="details-showcase-header"
        style={{ backgroundColor: color }}
      >
        {heading}
      </div>
      <ul className="inner-content">
        {obj.map((v, i) => {
          if (v.name === "") {
            return v.value === "" ? "-" : <span key={i}>{v.value}</span>;
          }
          return (<li key={i}>
            <span>{v.name}</span> <span> : </span>
            <span>{v.value}</span>
          </li>)
        })}
      </ul>
    </div>
  );
};
