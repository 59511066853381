import React from "react";
import { IoMdArrowDown, IoMdArrowUp } from "react-icons/io";
import { MdSort } from "react-icons/md";
import { StringsType } from "../../../../models";

export const StringsTable = ({ data }: { data: StringsType[] }) => {
  const [arrange, setArrange] = React.useState({
    name: "StringName",
    sortType: "asc",
  });

  switch (arrange.name) {
    case "Current":
      arrange.sortType === "asc"
        ? data.sort((a, b) => a.current - b.current)
        : data.sort((a, b) => b.current - a.current);
      break;
    case "Power":
      arrange.sortType === "asc"
        ? data.sort((a, b) => a.power - b.power)
        : data.sort((a, b) => b.power - a.power);
      break;
    default:
      arrange.sortType === "asc"
        ? data.sort((a, b) => (a.id === b.id ? 0 : a.id > b.id ? 1 : -1))
        : data.sort((a, b) => (a.id === b.id ? 0 : a.id < b.id ? 1 : -1));
      break;
  }
  const sortBasedOn = (val: string) => {
    let sortType: string;
    if (arrange.sortType === "asc") sortType = "desc";
    else sortType = "asc";
    setArrange({ name: val, sortType: sortType });
  };
  const listItems = data.map((v, i) => <SingleStringRow data={v} key={i} />);
  return (
    <td colSpan={3}>
      <div className="animate-data">
        <table className="strings-table">
          <thead>
            <tr>
              <th onClick={() => sortBasedOn("StringName")}>
                StringName{" "}
                {arrange.name === "StringName" ? (
                  arrange.sortType === "desc" ? (
                    <IoMdArrowDown />
                  ) : (
                    <IoMdArrowUp />
                  )
                ) : (
                  <MdSort />
                )}
              </th>
              <th onClick={() => sortBasedOn("Current")}>
                Current (A){" "}
                {arrange.name === "Current" ? (
                  arrange.sortType === "desc" ? (
                    <IoMdArrowDown />
                  ) : (
                    <IoMdArrowUp />
                  )
                ) : (
                  <MdSort />
                )}
              </th>
              <th onClick={() => sortBasedOn("Power")}>
                Power (W){" "}
                {arrange.name === "Power" ? (
                  arrange.sortType === "desc" ? (
                    <IoMdArrowDown />
                  ) : (
                    <IoMdArrowUp />
                  )
                ) : (
                  <MdSort />
                )}
              </th>
            </tr>
          </thead>
          <tbody>{listItems}</tbody>
        </table>
      </div>
    </td>
  );
};

const SingleStringRow = ({ data }: { data: StringsType }) => {
  return (
    <tr>
      <td>{data.id}</td>
      <td>{data.current.toFixed(2)}</td>
      <td>{data.power.toFixed(2)}</td>
    </tr>
  );
};
