import React, { useState } from "react";
import toast from "react-hot-toast";
import {
  useFrequentData,
  useSiteQuery,
  useTotalPowerGenData,
  useVCBData,
} from "../../utils/react-query-hooks";
import { DetailsContainer } from "../Charts";
import { DetailsShowCase } from "../ShowCaseContainer/DetailsShowCase/DetailsShowCase";
import { Spinner } from "../UI";
// import { useEffect } from "react";

export const DashboardHeader: React.FC<{ site: string; inverter: string }> = ({
  site,
  inverter,
}) => {
  // const [nextTimeStamp, setNextTimeStamp] = useState([''])
  const [sensitive,setSensitive] = useState(true)
  const {
    data: frequentData,
    isLoading: freqLoading,
    isError: isFreqError,
    error: freqError,
  } = useFrequentData([site, inverter]);

  const {
    data: vcbData,
    isLoading: vcbLoading,
    isError: isvcbError,
    error: vcbError,
  } = useVCBData([site, inverter]);

  const { data } = useSiteQuery();
  const { data: totalPowerGen } = useTotalPowerGenData([
    site,
    inverter,
    "powerGen",
  ]);

  // useEffect(() => {
  //   fetch("https://api.open-meteo.com/v1/forecast?latitude=10.69&longitude=77.87&hourly=temperature_2m&past_days=1&timeformat=unixtime&timezone=auto", { method: "GET" }).then(
  //     (res) => res.json()
  //   ).then((res) => {
  //     const temperature = res?.hourly?.temperature_2m
  //     const time = res?.hourly?.time
  //     let resObj: { [index: string]: number } = {}
  //     Array.from({ length: time.length }, (_, i) => {
  //       const _key = (time[i] * 1000).toString()
  //       resObj[_key] = temperature[i]
  //     })
  //     const today = getNextWeatherData(resObj)
  //     setNextTimeStamp([resObj[today.toString()].toString(), resObj[(today + 86400000).toString()].toString(), resObj[(today + 172800000).toString()].toString()])

  //   })
  // }, [])
  // const getNextWeatherData = (resObj: Object): number => {
  //   const _now = new Date().getTime()
  //   // const morning = new Date(new Date().toDateString()).getTime() + 3600000
  //   // let _nextTimeStamp = morning + (3600000 * Math.ceil((_now - morning) / 3600000))
  //   let _nextTimeStamp;
  //   let timestampStrings = Object.keys(resObj);
  //   for (let timeStamp of timestampStrings) {
  //     let parsed_timestamp = parseInt(timeStamp, 10)
  //     if (parsed_timestamp > _now) {
  //       _nextTimeStamp = parsed_timestamp;
  //       break;
  //     }
  //   }
  //   return _nextTimeStamp || 0;
  // }
  if (freqLoading || vcbLoading) {
    return <Spinner />;
  }

  if (isFreqError || isvcbError) {
    freqError && toast.error(freqError.message);
    vcbError && toast.error(vcbError.message);
  }
  // let capacity;
  // if (data) {
  //   const found: any = data.find((s) => s.name === site);
  //   capacity = found?.capacity ? found.capacity : 0;
  // }
  const formatUnits = (val: number) => {
    let _arr: Array<string> = []
    while (val > 0) {
      let _tmp = val % 100
      _arr.push(_tmp > 9 ? _tmp.toString() : '0' + _tmp)
      val = Math.trunc(val / 100)
    }
    _arr.reverse()
    const _res = _arr.join(',')
    return _res[0] === '0' ? _res.substring(1) : _res
  }

  const getInverterStatus = (sts: number) => {
    const _val = sts.toString(2).split("").reverse();
    for (let i = _val.length; i <= 15; i++) _val[i] = "0";
    const res = (() => {
      if (_val[1] === "1") return "Fault Detected";
      if (_val[2] === "1") return "Warning";
      if (_val[5] === "1") return "Producing Power";
      if (_val[0] === "1") return "Ready to switch on";
      return "Ideal";
    })();
    return { inverterStatus: res, invBool: _val[5] === "1" ? "ON" : "OFF" };
  }
  const { inverterStatus, invBool } = getInverterStatus(frequentData?.inverterStatus ?? 0)

  const addZeros = (val: number) => {
    if (val < 10)
      return "00" + val
    if (val < 100)
      return "0" + val
    return val
  }
  return (
    <>
    <div className="sensitive-data" onClick={()=>setSensitive(prev=>!prev)}>
        {sensitive?"Show Sensitive Data":"Hide Sensitive Data"}
      </div>
    <div className="current-and-live">
      
      <DetailsContainer
        heading="Units Produced"
        site={site}
        inverter={inverter}
      />
      {data && (
        <DetailsShowCase
          heading="Ambient Temperature"
          obj={[
            {
              name: "Main air channel Temperature",
              value: `${frequentData ? frequentData["ambientTemperature"] : "-"} ℃`,
            }
          ]}
          color={frequentData ? (frequentData["ambientTemperature"] < 50 ? "blue" : "black") : "black"}
        />
      )}
      <DetailsShowCase
        heading="Active Power"
        color={frequentData ? (frequentData["activePower"] > 0 ? "red" : "black") : "black"}
        obj={[
          {
            name: "Active Power",
            value: `${frequentData ? (frequentData["activePower"]).toFixed(2) : 0
              } kW`,
          },
        ]}
      />
      <DetailsShowCase
        heading="Inverter Status"
        color={inverterStatus === "Producing Power" ? "green" : "black"}
        obj={[
          {
            name: '',
            value: inverterStatus,
          },
          {
            name: "inverterStatus",
            value: invBool
          },
        ]}

      />
      {/* <DetailsShowCase
        heading="Weather Report"
        color={"gold"}
        obj={[
          {
            name: 'Yesterday',
            value: nextTimeStamp[0] + ' ℃'
          },
          {
            name: "Today",
            value: nextTimeStamp[1] + ' ℃'
          },
          {
            name: "Tomorrow",
            value: nextTimeStamp[2] + ' ℃'
          },
        ]}

      /> */}
      <DetailsShowCase
        heading="Total Units Produced"
        color="black"
        obj={[
          // {
          //   name: "giga Watt",
          //   value: `${totalPowerGen ? totalPowerGen["GHCnt"] : 0} gW`,
          // },
          // {
          //   name: "mega Watt",
          //   value: `${totalPowerGen ? totalPowerGen["MHCnt"] : 0} mW`,
          // },
          // {
          //   name: "kilo Watt",
          //   value: `${totalPowerGen ? totalPowerGen["KHCnt"] : 0} kW`,
          // },
          {
            name: "",
            value: `${totalPowerGen ? (formatUnits(totalPowerGen["GHCnt"] * 1000 + totalPowerGen["MHCnt"]) + "," + addZeros(totalPowerGen["KHCnt"])) : 0} units`,
          },
        ]}
        hide={sensitive}
      />
      <DetailsShowCase
        heading="VCB Information"
        color="brown"
        customClass="vcb"
        obj={[
          {
            name: '1a-phaseCurrent',
            value: vcbData ? vcbData['1a-phaseCurrent'].toString() : '',
          },
          {
            name: '1b-phaseCurrent',
            value: vcbData ? vcbData['1b-phaseCurrent'].toString() : '',
          },
          {
            name: '1c-phaseCurrent',
            value: vcbData ? vcbData['1c-phaseCurrent'].toString() : '',
          },
          {
            name: '1g-earthCurrent',
            value: vcbData ? vcbData['1g-earthCurrent'].toString() : '',
          },
          {
            name: "Trip Counter",
            value: vcbData ? vcbData['tripCounter'].toString() : ''
          },
        ]}

      />
    </div>
    </>
  );
};
