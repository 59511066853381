import moment from "moment";
import React from "react";
import { FiMinus, FiPlus } from "react-icons/fi";
import { GoInfo } from "react-icons/go";
import { ArrayJunctionBox } from "../../../models";
import { StringsTable } from "./StringsTable";

export const JunctionBox = ({ data }: { data: ArrayJunctionBox }) => {
  const [expand, setExpand] = React.useState(false);

  return (
    <>
      <tr onClick={() => setExpand((prev) => !prev)}>
        <td
          style={{
            position: "relative",
            padding: "0 .5rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            lineHeight: 2,
          }}
        >
          <div
            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
          >
            {expand ? <FiMinus size={20} /> : <FiPlus size={20} />}
          </div>
          {data.id}
          <ToolTip last_updated={data.last_updated} />
        </td>
        <td>{data.voltage.toFixed(1)}</td>
        <td>{data.total_current.toFixed(1)}</td>
      </tr>
      <tr
        className="strings-table-container"
        data-active={expand ? "active" : "inactive"}
      >
        <StringsTable data={data.string_data} />
      </tr>
    </>
  );
};

const ToolTip = ({ last_updated }: { last_updated: number }) => {
  return (
    <div
      style={{ display: "flex", alignItems: "center", position: "relative" }}
    >
      <GoInfo className="tool-tip-btn" />
      <div className="tool-tip-info">
        {moment.unix(last_updated).format("hh:mm:ss a")}
      </div>
    </div>
  );
};
