import React, { useState } from "react";
import { JunctionBox } from "./JunctionBox";
import "./ArrayTable.scss";
import { IoMdArrowDown, IoMdArrowUp } from "react-icons/io";
import { MdSort } from "react-icons/md";
import { Spinner } from "../UI/Spinner";
import { useAjbValue } from "../../utils/react-query-hooks";
import { StringsType } from "../../models";

interface ArrayTableProps {
  site: string;
  inverter: string;
  customStyle: object;
}

export const ArrayTable: React.FC<ArrayTableProps> = ({
  site,
  inverter,
  customStyle,
}) => {
  const StringsNotUsed: { [index: string]: Array<number> } = {};
  const [arrange, setArrange] = useState({
    name: "Name",
    sortType: "asc",
  });
  let { data, isLoading } = useAjbValue([site, inverter]);

  if (isLoading) {
    return <Spinner />;
  }

  switch (arrange.name) {
    case "Voltage":
      arrange.sortType === "asc"
        ? data?.sort((a, b) => a.voltage - b.voltage)
        : data?.sort((a, b) => b.voltage - a.voltage);
      break;
    case "Total Current":
      arrange.sortType === "asc"
        ? data?.sort((a, b) => a.total_current - b.total_current)
        : data?.sort((a, b) => b.total_current - a.total_current);
      break;
    default:
      arrange.sortType === "asc"
        ? data?.sort((a, b) => (a.id === b.id ? 0 : a.id > b.id ? 1 : -1))
        : data?.sort((a, b) => (a.id === b.id ? 0 : a.id < b.id ? 1 : -1));
      break;
  }
  const sortBasedOn = (val: string) => {
    let sortType: string;
    if (arrange.sortType === "asc") sortType = "desc";
    else sortType = "asc";
    setArrange({ name: val, sortType: sortType });
  };

  const listItemsData = data?.map((v, i) => {
    let _key = (i + 1).toString();
    const res = () => {
      let res: Array<StringsType> = [...v.string_data];
      for (let _el of StringsNotUsed[_key]) {
        res = [...res.filter((f) => Number(f.id) !== _el)];
      }
      return { ...v, string_data: res };
    };
    return StringsNotUsed.hasOwnProperty(_key) ? res() : v;
  });

  const listItems = listItemsData?.map((v, i) => (
    <JunctionBox data={v} key={i} />
  ));
  return (
    <div className="array-junction-box-container" style={{ ...customStyle }}>
      <h3>String Monitoring Box</h3>
      <table className="array-table">
        <thead>
          <tr>
            <th onClick={() => sortBasedOn("Name")}>
              Name{" "}
              {arrange.name === "Name" ? (
                arrange.sortType === "desc" ? (
                  <IoMdArrowDown />
                ) : (
                  <IoMdArrowUp />
                )
              ) : (
                <MdSort />
              )}
            </th>
            <th onClick={() => sortBasedOn("Voltage")}>
              Voltage (V){" "}
              {arrange.name === "Voltage" ? (
                arrange.sortType === "desc" ? (
                  <IoMdArrowDown />
                ) : (
                  <IoMdArrowUp />
                )
              ) : (
                <MdSort />
              )}
            </th>
            <th onClick={() => sortBasedOn("Total Current")}>
              Total Current (A){" "}
              {arrange.name === "Total Current" ? (
                arrange.sortType === "desc" ? (
                  <IoMdArrowDown />
                ) : (
                  <IoMdArrowUp />
                )
              ) : (
                <MdSort />
              )}
            </th>
          </tr>
        </thead>
        <tbody>{listItems}</tbody>
      </table>
    </div>
  );
};
