import React, { useState } from "react";
import "./InvChart.scss";
import { Chart } from "./Chart";
import { CSVLink } from "react-csv";
import toast from "react-hot-toast";
import { db } from "../../../firebase";
import { Spinner } from "../../UI";
import { getInverterColor, getTimestamps } from "../../../utils";
import { DropDown } from "../../UI/DropDown";

import { ref } from "firebase/database";

import moment from "moment";
import {
  useAnalyticalData,
  useTimeRangeData,
  useDayValue,
  useFrequentData,
} from "../../../utils/react-query-hooks";
import { AnalysisTable } from "../../AnalysisTable";

export const InvChart = ({
  title = "",
  analytics = false,
  site = "",
  inverter = "",
}) => {
  const useDataQuery = (view: string, analytics: boolean) => {
    const dbRef = ref(db, `/monthly_data/${site}/${inverter}/daily`);

    const inbetweenData = useAnalyticalData(
      [site, inverter],
      startDate,
      endDate,
      dbRef
    );
    const [today, week, month] = getTimestamps();
    const weeklyData = useTimeRangeData([site, "weekly"], week, dbRef);
    const monthlyData = useTimeRangeData([site, "monthly"], month, dbRef);
    const todayData = useDayValue([site, inverter], today);

    if (analytics) return inbetweenData;

    switch (view) {
      case "weekly": {
        return weeklyData;
      }
      case "monthly": {
        return monthlyData;
      }
      default: {
        return todayData;
      }
    }
  };

  const viewType = ["daily", "weekly", "monthly"];
  const chartType = ["bar", "area", "cum(bar)", "cum(area)"];

  const [view, setView] = useState(viewType[0]);
  const [chart, setChart] = useState(chartType[0]);

  const now = moment().format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(() =>
    moment().subtract(1, "d").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(now);

  const selectedView = (selectedVal: string) => {
    setView(selectedVal);
  };

  const selectedChart = (selectedChart: string) => {
    setChart(selectedChart);
  };

  const { data, isError, isLoading, error } = useDataQuery(view, analytics);
  const { data: frequentData } = useFrequentData([site, inverter]);

  if (isError) {
    toast.error(error ? error.message : "");
  }
  let headers;
  if (data && analytics) {
    headers = Object.keys(data[0]).map((key) => {
      return {
        label:
          key === "timestamp"
            ? "Date/Time"
            : key.charAt(0).toUpperCase() + key.slice(1),
        key,
      };
    });
  }

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className="make-content-center">
      {analytics && (
        <div className="top-row">
          <div className="date-picker">
            <label>From Date: </label>
            <input
              type="date"
              onChange={(e) => {
                if (e.target.value === "") {
                  e.preventDefault();
                  setStartDate(now);
                } else {
                  setStartDate(e.target.value);
                }
              }}
              value={startDate}
              max={endDate}
            />
          </div>
          <div className="date-picker">
            <label>To Date : </label>
            <input
              type="date"
              onChange={(e) => {
                if (e.target.value === "") {
                  e.preventDefault();
                  setEndDate(now);
                } else {
                  setEndDate(e.target.value);
                }
              }}
              value={endDate}
              min={startDate}
              max={now}
            />
          </div>
          <div className="export-to-csv-btn">
            {/* {console.log(JSON.stringify(data))} */}
            <CSVLink
              filename={
                startDate === endDate
                  ? `${startDate}.csv`
                  : `${startDate}_to_${endDate}.csv`
              }
              style={{ textDecoration: "none" }}
              data={data ? data : ""}
              headers={headers}
            >
              Export to Csv
            </CSVLink>
          </div>
        </div>
      )}
      <div className="chart-container">
        <div className="chart-container-title">
          {title}
          <DropDown
            list={chartType}
            selectFun={selectedChart}
            selected={chart}
            customClass="inverted reduce-space"
          />
          {!analytics && (
            <DropDown
              list={viewType}
              selectFun={selectedView}
              selected={view}
              customClass="inverted reduce-space"
            />
          )}
          {!analytics && (
            <div
              className="status-circle"
              style={{
                backgroundColor: getInverterColor(
                  frequentData ? frequentData.inverterStatus : 2
                ),
              }}
            ></div>
          )}
        </div>
        <div className="chart">
          <Chart
            chartType={chart}
            data={data ? data : []}
            type={view}
            labels={[{ color: "#82ca9d", name: inverter, hidden: false }]}
            dataKey="unitsProduced"
          />
        </div>
      </div>

      {analytics && <AnalysisTable data={data ? data : []} />}
    </div>
  );
};
