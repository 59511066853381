import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import {
  collection,
  doc,
  query,
  where,
  limitToLast,
  orderBy,
} from "firebase/firestore";
import {
  child,
  DatabaseReference,
  endAt,
  orderByKey,
  query as dbQuery,
  ref,
  startAt,
} from "firebase/database";
import { modifyData, useDatabaseQueryValue } from "./index";
import toast from "react-hot-toast";
import moment from "moment";
import { useDatabaseValue } from "@react-query-firebase/database";
import {
  ArrayJunctionBox,
  FrequentData,
  SiteData,
  TimestampData,
  vcbData,
} from "../models";
import { db, firestore } from "../firebase";
import { modData } from "./index";

export const useDayValue = (keys: string[], start: string | number) => {
  const [site, inverter] = keys;
  const now = new Date().getTime() / 1000;
  const collRef = collection(
    doc(collection(firestore, "site_data"), site),
    inverter
  );
  const end = moment
    .unix(typeof start === "number" ? start : 0)
    .endOf("day")
    .unix();
  return useFirestoreQueryData(
    [...keys, start, "daily"],
    query(
      collRef,
      where("timestamp", ">=", start),
      where("timestamp", "<=", end)
    ),
    {
      subscribe: now >= start && now <= end,
    },

    {
      retry: false,
      onError(err) {
        toast.error(err.message);
      },
      staleTime: Infinity,
      refetchOnMount: false,
      retryOnMount: false,
      select: (data) => modData(data),
    }
  );
};

export const useTimeRangeData = (
  keys: string[],
  start: string | number,
  dbRef: DatabaseReference
) => {
  return useDatabaseQueryValue(
    [...keys, start],
    dbQuery(dbRef, orderByKey(), startAt(start)),
    {},
    {
      retry: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      select: (data: any) => modifyData(data, start),
    }
  );
};
export const useAnalyticalData = (
  keys: string[],
  start: string,
  end: string,
  dbRef: DatabaseReference
) => {
  const today = useDayValue(keys, moment(start, "YYYY-MM-DD").unix());
  const timeRange = useDatabaseQueryValue(
    [...keys, start, end],
    dbQuery(dbRef, orderByKey(), startAt(start), endAt(end)),
    {},
    {
      retry: false,
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      select: (data: any) => modifyData(data, start, end),
    }
  );
  if (start === end) {
    return today;
  } else {
    return timeRange;
  }
};

export const useAjbValue = (keys: string[]) => {
  const [site, inverter] = keys;
  const dbRef = ref(db, `site_data/${site}/${inverter}`);
  return useDatabaseValue<Array<ArrayJunctionBox>>(
    [...keys, "ajb"],
    dbRef,
    {
      subscribe: true,
    },
    {
      retry: false,
      onError(err) {
        toast.error(err.message);
      },
      refetchOnMount: false,
      staleTime: 30000,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    }
  );
};

export const useDashboardData = (
  keys: string[],
  subscribe = true,
  month = false
) => {
  const [site, inverter, type] = keys;

  const dbRef = ref(db, `monthly_data/${site}/${inverter}`);

  return useDatabaseValue<number>(
    keys,
    child(dbRef, month ? `monthly/${type}` : `daily/${type}`),
    {
      subscribe: subscribe,
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSiteQuery = () => {
  const collRef = collection(firestore, "sites");
  return useFirestoreQueryData<unknown, SiteData[]>(
    "sites",
    query(collRef),
    {},
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
    }
  );
};

export const useFrequentData = (keys: string[]) => {
  const [site, inverter] = keys;

  const dbRef = ref(db, `frequent_data/${site}/${inverter}/`);
  return useDatabaseValue<FrequentData>(
    [...keys, "frequentData"],
    dbRef,
    {
      subscribe: true,
    },
    {
      staleTime: 15000,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
    }
  );
};

export const useVCBData = (keys: string[]) => {
  const [site, inverter] = keys;

  const dbRef = ref(db, `vcb_data/${site}/${inverter}/`);
  return useDatabaseValue<vcbData>(
    [...keys, "vcbData"],
    dbRef,
    {
      subscribe: true,
    },
    {
      staleTime: 15000,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
    }
  );
};

export const useTotalPowerGenData = (keys: string[]) => {
  const [site, inverter] = keys;
  const collRef = collection(
    doc(collection(firestore, "site_data"), site),
    inverter
  );

  return useFirestoreQueryData(
    keys,
    query(collRef, orderBy("timestamp"), limitToLast(1)),
    {
      subscribe: true,
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
      select: (data: any) => {
        const resultData = { ...data[0], ...data[0]["data"] };
        return resultData as TimestampData;
      },
    }
  );
};
