// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyA4BBiPub1QHNisH6Xa1RvvdG4pkK0z0JI",
  authDomain: "trisshanth-solar-power-plant.firebaseapp.com",
  databaseURL: "https://trisshanth-solar-power-plant-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "trisshanth-solar-power-plant",
  storageBucket: "trisshanth-solar-power-plant.appspot.com",
  messagingSenderId: "461472553588",
  appId: "1:461472553588:web:edbaffd23fdf112c568f21",
  measurementId: "G-PE0WEDP20M"
};
// const firebaseConfig = {
//   apiKey: "AIzaSyAdL9HJ6tMVRG1FJlJayiJTS9qQjWqW8ws",
//   authDomain: "test-inv-ajb.firebaseapp.com",
//   databaseURL: "https://test-inv-ajb-default-rtdb.asia-southeast1.firebasedatabase.app",
//   projectId: "test-inv-ajb",
//   storageBucket: "test-inv-ajb.appspot.com",
//   messagingSenderId: "204710222817",
//   appId: "1:204710222817:web:52793d4ffac15c268c10a0"
// };


// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const db = getDatabase(app)
export const storage = getStorage(app)
export const firestore = getFirestore(app)
