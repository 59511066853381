import React, { useEffect, useState } from "react";
import "./Dashboard.scss";

import { InvChart /* PieChart */ } from "../../components/Charts";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { app } from "../../firebase";
import { getAuth } from "firebase/auth";
import { DropDown } from "../../components/UI/DropDown";
import { ArrayTable } from "../../components/ArrayTable";
import { Spinner } from "../../components/UI";
import { useSiteQuery } from "../../utils/react-query-hooks";
// import { DetailsShowCase } from "../../components/ShowCaseContainer/DetailsShowCase/DetailsShowCase";
import { DashboardHeader } from "../../components/DashboardHeader";
import { Clock } from "../../components/Clock";

export const Dashboard = () => {
  const navigate = useNavigate();
  const auth = getAuth(app);
  useEffect(() => {
    const unsub = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate("/");
      }
      setInitialValues();
    });
    return unsub;
  }, []);

  const { data, isSuccess, isLoading } = useSiteQuery();

  const siteData = data;

  const [site, setSite] = useState(siteData ? siteData[0].name : "");
  let invList = siteData ? siteData.filter((v) => v.name === site) : [];
  const [inverter, setInverter] = useState(
    invList[0] ? invList[0].inverters[0] : ""
  );

  const selectSite = (selectedVal: string) => {
    setSite(selectedVal);
  };
  useEffect(() => {
    invList = siteData ? siteData.filter((v) => v.name === site) : [];
    setInverter(invList[0] ? invList[0].inverters[0] : "");
  }, [site]);

  const selectedInv = (selectedVal: string) => {
    setInverter(selectedVal);
  };

  const setInitialValues = () => {
    if (isSuccess) {
      setSite(siteData ? siteData[0].name : "");
      invList = siteData ? siteData.filter((v) => v.name === site) : [];
      setInverter(invList[0] ? invList[0].inverters[0] : "");
    }
  };
  if (isLoading) {
    return <Spinner />;
  }
  if (site === "" || inverter === "") {
    setInitialValues();
    return <Spinner />;
  }

  if (siteData ? siteData.filter(v => v.name === site)[0].inverters.filter((p: string) => p === inverter).length === 0 : true) {
    return <Spinner />;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
      className="dashboard-container"
    >
      <div className="site-inv-dropdowns">
        <Clock />
        <DropDown
          list={siteData ? siteData.map((v) => v.name) : [""]}
          selectFun={selectSite}
          selected={site}
          customClass=""
        />
        <DropDown
          list={invList[0] ? invList[0].inverters : [""]}
          selectFun={selectedInv}
          selected={inverter}
          customClass=""
        />
      </div>

      <DashboardHeader site={site} inverter={inverter} />

      <InvChart title="Current Chart" inverter={inverter} site={site} />

      {/* <ShowCaseContainer /> */}
      <div className="ArrayTable-and-showcase-holder">
        <ArrayTable inverter={inverter} site={site} customStyle={{ flex: 3 }} />

        {/* <DetailsShowCase
          heading="Breaker"
          obj={[
            { name: "Phase 1", value: "10mA" },
            { name: "Phase 2", value: "20A" },
            { name: "Phase 3", value: "40mA" },
            { name: "Property 4", value: "-" },
            { name: "Property 5", value: "-" },
          ]}
          color="blue"
          customStyle={{ marginTop: "2.8rem" }}
        /> */}
      </div>

      {/* <PieChart data={workerData} /> */}
    </motion.div>
  );
};
