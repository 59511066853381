import moment from "moment";
import React, { useState } from "react";

export const Clock = () => {
  const now = () => moment().format("Do MMMM YYYY | hh:mm:ss a");
  const [time, setTime] = useState(now());
  React.useEffect(() => {
    const unsub = setInterval(() => {
      setTime(now());
    }, 1000);
    return () => {
      clearInterval(unsub);
    };
  });
  const dateTime = time.split(",");
  return (
    <label className="timer-label">
      <span style={{ color: "white" }}>{dateTime}</span>
    </label>
  );
};
