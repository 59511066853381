import { getAuth } from 'firebase/auth'
import React, { useEffect } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { app } from '../../firebase'

export const Logout: React.FC = () => {
  const auth = getAuth(app)
  const navigate = useNavigate()
  useEffect(() => {
    const unsub = auth.onAuthStateChanged((user) => {
      if (user) {
        toast.promise(auth.signOut(), {
          loading: 'Signing out user',
          success: () => {
            return `${user.displayName} Logged out`
          },
          error: () => `Cannot Logout User ${user.displayName}`,
        })
      }
      navigate('/')
    })
    return unsub
  }, [])

  return <div></div>
}
