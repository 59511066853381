import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { app, firestore } from "../../firebase";
import { getAuth } from "firebase/auth";
import { DropDown } from "../../components/UI/DropDown";
import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import { collection, query } from "@firebase/firestore";
import { Spinner } from "../../components/UI";
import { InvChart } from "../../components/Charts";
import "./Analytics.scss";
import { Clock } from "../../components/Clock";

export const Analytics = () => {
  const navigate = useNavigate();
  const auth = getAuth(app);
  useEffect(() => {
    const unsub = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate("/");
      }
      setInitialValues();
    });
    return unsub;
  }, []);
  const collRef = collection(firestore, "sites");
  const fetchedData = useFirestoreQueryData("sites", query(collRef));

  const siteData = fetchedData.data;
  const [site, setSite] = useState(siteData ? siteData[0].name : "");
  let invList = siteData ? siteData.filter((v) => v.name === site) : [];
  const [inv, setInv] = useState(invList[0] ? invList[0].inverters[0] : "");

  const selectSite = (selectedVal: string) => {
    setSite(selectedVal);
  };
  useEffect(() => {
    invList = siteData ? siteData.filter((v) => v.name === site) : [];
    setInv(invList[0] ? invList[0].inverters[0] : "");
  }, [site]);
  const selectedInv = (selectedVal: string) => {
    setInv(selectedVal);
  };

  const setInitialValues = () => {
    if (fetchedData.isSuccess) {
      setSite(siteData ? siteData[0].name : "");
      invList = siteData ? siteData.filter((v) => v.name === site) : [];
      setInv(invList[0] ? invList[0].inverters[0] : "");
    }
  };

  if (fetchedData.isLoading) {
    return <Spinner />;
  }
  if (site === "" || inv === "") {
    setInitialValues();
  }
  return (
    <div className="analytics-container">
      <div className="site-inv-dropdowns">
        <Clock />
        <DropDown
          list={siteData ? siteData.map((v) => v.name) : [""]}
          selectFun={selectSite}
          selected={site}
          customClass=""
        />
        <DropDown
          list={invList[0] ? invList[0].inverters : [""]}
          selectFun={selectedInv}
          selected={inv}
          customClass=""
        />
      </div>
      <InvChart title="Current Chart" inverter={inv} site={site} analytics />
    </div>
  );
};
