import React, { ChangeEvent, useEffect, useState } from "react";
import "./Login.scss";
import img from "../../img/trisshanth-logo.jpg";
import {
  getAuth,
  signInWithEmailAndPassword,
  AuthError,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  ConfirmationResult,
} from "firebase/auth";
import { app } from "../../firebase";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { Spinner, InputField } from "../../components/UI";
import toast from "react-hot-toast";
import { InputType } from "../../models";
import validate from "../../components/UI/InputField/validation";

export interface ConfirmResult extends Window {
  recaptcha: RecaptchaVerifier;
  confirmationResult: ConfirmationResult;
}

declare let window: ConfirmResult;

export const Login: React.FC = () => {
  const navigate = useNavigate();
  const auth = getAuth(app);

  useEffect(() => {
    const unsub = auth.onAuthStateChanged((user) => {
      if (user) {
        toast.success(`${user.displayName} Already Logged in`);
        user && navigate("/dashboard");
      }
    });
    return unsub;
  }, []);

  const emailAuthCfg: { authId: InputType; secret: InputType } = {
    authId: {
      elementConfig: {
        label: "Email ID",
        placeHolder: "Enter your Email ID",
        type: "email",
        value: "",
      },
      isValid: false,
      validation: {
        required: true,
        isEmail: true,
      },
      edit: false,
    },
    secret: {
      elementConfig: {
        label: "Password",
        placeHolder: "Enter your Password",
        type: "password",
        value: "",
      },
      isValid: false,
      validation: {
        required: true,
        minLength: 8,
      },
      edit: false,
    },
  };

  const mobAuthCfg: {
    authId: InputType;
    secret: InputType;
  } = {
    authId: {
      elementConfig: {
        label: "Mobile No",
        placeHolder: "Enter your Mobile No",
        type: "text",
        value: "",
      },
      isValid: false,
      validation: {
        required: true,
        minLength: 10,
        maxLength: 10,
      },
      edit: false,
    },
    secret: {
      elementConfig: {
        label: "OTP",
        placeHolder: "Enter your OTP",
        type: "text",
        value: "",
      },
      isValid: false,
      validation: {
        required: true,
        minLength: 6,
        maxLength: 6,
      },
      edit: false,
    },
  };
  const [state, setState] = useState({ ...emailAuthCfg, mobAuth: false });
  const [loading, setLoading] = useState(false);
  const [otpSend, setOtpSend] = useState(false);

  const genRecaptcha = () => {
    window.recaptcha = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "normal",
        callback: () => {
          setLoading(true);
        },
      },
      auth
    );
    setLoading(false);
  };

  const clearRecaptcha = () => {
    window.recaptcha.clear();
  };

  const mobAuthSubmit = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    if (!state.authId.isValid)
      return toast.error("Mobile Number must be 10 digits");

    if (otpSend && !state.secret.isValid)
      return toast.error("OTP must be 6 digits");

    if (otpSend && state.mobAuth) return verifyOTP();

    genRecaptcha();

    toast.promise(
      signInWithPhoneNumber(
        auth,
        `+91${state.authId.elementConfig.value}`,
        window.recaptcha
      ),
      {
        loading: "Loading",
        success: (confirmationResult) => {
          setLoading(false);
          clearRecaptcha();
          setOtpSend(true);
          window.confirmationResult = confirmationResult;

          return "OTP sent";
        },
        error: () => {
          setLoading(false);
          clearRecaptcha();
          return "OTP cannot be sent try again later";
        },
      }
    );
  };

  const emailAuthSubmit = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    setLoading(true);

    if (!state.authId.isValid || !state.secret.isValid) {
      const invalidFeilds = [];
      if (!state.authId.isValid) {
        invalidFeilds.push(state.authId.elementConfig.label);
      }
      if (!state.secret.isValid) {
        invalidFeilds.push(state.secret.elementConfig.label);
      }
      setLoading(false);

      return toast.error(
        `Cannot Submit due to invalid ${invalidFeilds.join(",")} feild(s)`
      );
    }

    toast.promise(
      signInWithEmailAndPassword(
        auth,
        state.authId.elementConfig.value,
        state.secret.elementConfig.value
      ),
      {
        loading: "Verifying Recaptcha",
        success: (userCredential) => {
          userCredential.user.getIdTokenResult(true).then((user) => {
            setLoading(false);
            navigate("/dashboard");
          });
          return "Login Successful";
        },
        error: (error: AuthError) => {
          setLoading(false);
          return `Login Failed due to ${error.code
            .replace("auth/", "")
            .replaceAll("-", " ")}`;
        },
      }
    );
  };

  const verifyOTP = () => {
    setLoading(true);

    toast.promise(
      window.confirmationResult.confirm(state.secret.elementConfig.value),
      {
        loading: "Verifying OTP",
        success: (result) => {
          navigate("/dashboard");
          return "Login Successful";
        },
        error: (error) => {
          setOtpSend(false);
          setState((prev) => {
            return {
              ...prev,
              secret: {
                ...prev.secret,
                elementConfig: {
                  ...prev.secret.elementConfig,
                  value: "",
                },
              },
            };
          });

          return `Login Failed due to ${error.code
            .replace("auth/", "")
            .replaceAll("-", " ")}`;
        },
      }
    );

    setLoading(false);
  };

  const onAuthIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setState((prev) => {
      return {
        ...prev,
        authId: {
          ...prev.authId,
          elementConfig: {
            ...prev.authId.elementConfig,
            value: value,
          },
          edit: true,
          isValid: validate({
            ...prev.authId,
            elementConfig: {
              ...prev.authId.elementConfig,
              value: value,
            },
          }),
        },
      };
    });
  };

  const onSecretChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setState((prev) => {
      return {
        ...prev,
        secret: {
          ...prev.secret,
          elementConfig: {
            ...prev.secret.elementConfig,
            value: value,
          },
          edit: true,
          isValid: validate({
            ...prev.secret,
            elementConfig: {
              ...prev.secret.elementConfig,
              value: value,
            },
          }),
        },
      };
    });
  };

  const form = state.mobAuth ? (
    <React.Fragment>
      <InputField
        config={state.authId}
        onChange={onAuthIdChange}
        disabled={otpSend}
      />
      {otpSend ? (
        <InputField config={state.secret} onChange={onSecretChange} />
      ) : null}
      <button className="login-btn" onClick={mobAuthSubmit}>
        {otpSend ? "Verify Otp" : "Send OTP"}
      </button>

      <div id="recaptcha-container"></div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <InputField config={state.authId} onChange={onAuthIdChange} />
      <InputField config={state.secret} onChange={onSecretChange} />
      <button className="login-btn" onClick={emailAuthSubmit}>
        Login Now
      </button>
      <Link to="/forgot-password" className="login-forgot-password">
        Forgot Password?
      </Link>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {loading ? <Spinner /> : null}
      <motion.div
        initial={{ transform: "translateY(-100%)" }}
        animate={{ transform: "translateY(0%)" }}
        exit={{ transform: "translateY(100%" }}
        transition={{ duration: 0.5 }}
      >
        <div className="container">
          <form className="login-component">
            <div className="logo-container">
              <img src={img} alt="" />
            </div>
            <label className="login-greetings">WELCOME BACK</label>
            <h3>Log into your account</h3>
            {form}
            {/* {otpSend ? null : (
              <button
                className="login-btn small-btn"
                onClick={(event) => {
                  event.preventDefault();
                  setState((prev) => {
                    const config = prev.mobAuth ? emailAuthCfg : mobAuthCfg;
                    return {
                      ...config,
                      mobAuth: !prev.mobAuth,
                    };
                  });
                }}
              >
                Use another Auth method
              </button>
            )} */}

            <br/>
            <br/>
          </form>
        </div>
      </motion.div>
    </React.Fragment>
  );
};
