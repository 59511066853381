import React from 'react'
import { FiArrowDown } from 'react-icons/fi'
import './DropDown.scss'

export const DropDown = ({
  list,
  selectFun,
  selected,
  customClass,
  inputSearch = false,
}: {
  list: string[]
  selectFun: Function
  selected: string
  customClass?: String
  inputSearch?: boolean
}) => {
  const valueList: { [index: string]: string } = {
    "aravakurichi": "Trisshanth Solar",
    "AKF1MW": "Inverter-1",
    "AKB1MW": "Inverter-2"
  }

  const [freeze, setFreeze] = React.useState(false)

  const el = list.map((val: string, i) => {
    if (val === selected) return <React.Fragment key={i}></React.Fragment>
    return (
      <li onClick={() => selectFun(val)} key={i}>
        {valueList.hasOwnProperty(val) ? valueList[val] : val}
      </li>
    )
  })

  return (
    <div className={`drop-down-menu ${customClass}`}>
      {inputSearch ? (
        <input
          onChange={(e) => {
            console.log("--reset--")
            selectFun(e.target.value)

            console.log(e.target.value)
            setFreeze(true)
          }}
          value={selected}
          className="drop-down-search"
        />
      ) : (
        <p
          onClick={() => {
            setFreeze((prev) => !prev)
          }}
        >
          {valueList.hasOwnProperty(selected) ? valueList[selected] : selected}
          <FiArrowDown style={{ position: 'absolute', right: '0.2rem' }} />
        </p>
      )}

      <ul
        className={`drop-down-list ${freeze ? 'freeze' : ''}`}
        onClick={() => {
          setFreeze(false)
        }}
      >
        {el}
      </ul>
    </div>
  )
}
