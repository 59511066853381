import { sendPasswordResetEmail, getAuth } from "firebase/auth";
import { motion } from "framer-motion";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Spinner, InputField } from "../../components/UI";
import { app } from "../../firebase";
import img from "../../img/trisshanth-logo.jpg";
import { InputType } from "../../models";
import validate from "../../components/UI/InputField/validation";

export const ForgotPassword: React.FC = () => {
  const [state, setState] = useState<InputType>({
    elementConfig: {
      label: "E-Mail Id",
      placeHolder: "Enter your e-mail id",
      type: "email",
      value: "",
    },
    isValid: false,
    edit: false,
    validation: {
      isEmail: true,
      required: true,
    },
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth(app);

  const formSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    setLoading(true);
    toast.promise(sendPasswordResetEmail(auth, state.elementConfig.value), {
      loading: "Loading",
      success: () => {
        setLoading(false);
        navigate("/");
        return "Reset mail has been sent";
      },
      error: (error) => {
        setLoading(false);
        return `Cannot reset password due to ${error.code
          .replace("auth/", "")
          .replaceAll("-", " ")}`;
      },
    });
  };

  return (
    <React.Fragment>
      {loading ? <Spinner /> : null}
      <motion.div
        initial={{ transform: "translateY(-100%)" }}
        animate={{ transform: "translateY(0%)" }}
        exit={{ transform: "translateY(100%" }}
        transition={{ duration: 0.5 }}
      >
        <div className="container">
          <form className="login-component">
            <div className="logo-container">
              <img src={img} alt="" />
            </div>
            <h3>Enter the Email Id to Reset the password</h3>

            <InputField
              config={state}
              onChange={(event) =>
                setState((prev) => {
                  return {
                    ...prev,
                    edit: true,
                    isValid: validate(prev),
                    elementConfig: {
                      ...prev.elementConfig,
                      value: event.target.value,
                    },
                  };
                })
              }
            />

            <button className="login-btn" onClick={formSubmit}>
              Send Email
            </button>
          </form>
        </div>
      </motion.div>
    </React.Fragment>
  );
};
