import React from "react";
import "./App.scss";
import { Navigator } from "./components/Navigation";
import { Route, Routes, useLocation } from "react-router-dom";
// import { Employees } from "./pages/Employees";
import { Dashboard } from "./pages/Dashboard";
import { Analytics } from "./pages/Analytics";
import { Login } from "./pages/Login";
import { AnimatePresence } from "framer-motion";
import { ForgotPassword } from "./pages/Login/ForgotPassword";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Logout } from "./pages/Logout";
import { Alert } from "./pages/Alert";

const queryClient = new QueryClient();

function App() {
  const location = useLocation();
  return (
    <div className="app">
      <Toaster position="top-center" reverseOrder />
      <Navigator />
      <div className="main">
        <AnimatePresence exitBeforeEnter initial={false}>
          <QueryClientProvider client={queryClient}>
            <Routes location={location} key={location.pathname}>
              {/* <Route path="/employees" element={<Employees />} /> */}
              {/* <Route path="/alert" element={<Alert />} /> */}
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/analytics" element={<Analytics />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/" element={<Login />} />
            </Routes>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </AnimatePresence>
      </div>
    </div>
  );
}

export default App;
