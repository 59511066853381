import { motion, MotionStyle } from "framer-motion";
import React from "react";
import { Backdrop } from "../Backdrop";
import "./Modal.scss";

const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: "100vh",
    opacity: 0,
  },
};

export const Modal: React.FC<{
  onClick: VoidFunction;
  style?: MotionStyle;
}> = ({ onClick, children, style }) => {
  return (
    <Backdrop onClick={onClick}>
      <motion.div
        onClick={(e) => e.stopPropagation()} // Prevent click from closing modal
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="modal"
        style={style}
      >
        {children}
      </motion.div>
    </Backdrop>
  );
};
