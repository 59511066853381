import React from "react";
import "./Sidebar.scss";
import { NavLink } from "react-router-dom";

export interface SidebarProps {
  className: string;
  path: string;
  linkText?: string;
  icon: any;
}

export const SidebarItems: React.FC<SidebarProps> = ({
  className,
  path,
  linkText,
  icon,
}) => {
  return (
    <li className={className}>
      <NavLink
        to={path}
        className={({ isActive }) =>
          isActive ? "nav-link active" : "nav-link"
        }
      >
        {icon}
        {linkText ? <span className="link-text">{linkText}</span> : null}
      </NavLink>
    </li>
  );
};
