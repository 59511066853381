import React, { useEffect, useRef } from 'react'
import { animate } from 'framer-motion'

export const NumberCounter = ({
  from = 0,
  to = 0,
  duration = 1,
  className = '',
  unit = 'mW',
}) => {
  const nodeRef = useRef<HTMLParagraphElement>(null)
  useEffect(() => {
    const node = nodeRef.current

    const controls = animate(from, to, {
      duration: duration,
      onUpdate(value) {
        if (node?.textContent) {
          // node.innerText = value.toFixed(2).toString()
          node.innerText = value.toFixed(0).toString()
        }
      },
    })

    return () => {
      controls.stop()
    }
  }, [from, to, duration])

  return (
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center'
    }}>
      <p ref={nodeRef} className={className}>
        {to}
      </p>
      <span style={{ paddingLeft: '0.2rem' }}>{unit}</span>
    </div>
  )
}
