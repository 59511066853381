import React, { ChangeEvent, useState } from 'react'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { InputType } from '../../../models'
import './InputField.scss'

export interface PropsType {
  config: InputType
  disabled?: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  onFocusOut?: (event: any) => void
  onSubmit?: (event: any) => void
}

export const InputField: React.FC<PropsType> = ({
  config,
  disabled,
  onChange,
  onFocusOut,
  onSubmit,
}) => {
  const [eyelash, setEyelash] = useState(false)

  let classes = ['login-input']

  if (config.edit) {
    config.isValid ? classes.push('valid') : classes.push('invalid')
  }
  return (
    <div className="login-wrapper">
      <label className="login--label">{config.elementConfig.label}</label>
      <input
        type={eyelash ? 'text ' : config.elementConfig.type}
        className={classes.join(' ')}
        placeholder={config.elementConfig.placeHolder}
        value={config.elementConfig.value}
        onChange={onChange}
        onMouseOut={onFocusOut}
        onSubmit={onSubmit}
        disabled={disabled}
      />
      {config.elementConfig.type === 'password' ? (
        <div
          className="show-password"
          onClick={() => setEyelash((prev) => !prev)}
        >
          {eyelash ? <AiFillEyeInvisible /> : <AiFillEye />}
        </div>
      ) : null}
    </div>
  )
}
