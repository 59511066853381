import { UseDatabaseValueOptions } from "@react-query-firebase/database";
import { DataSnapshot, onValue, Query, Unsubscribe } from "firebase/database";
import { useQuery } from "react-query";
import moment from "moment";
import { useEffect, useRef } from "react";
import {
  QueryKey,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";
import { ArchiveData, TimestampData } from "../models";

export function getTimestamps() {
  return [
    moment().startOf("day").unix(),
    moment().subtract(6, "days").format("YYYY-MM-DD"),
    moment().startOf("month").format("YYYY-MM-DD"),
  ];
}

export function getTYM() {
  return [
    moment().format("YYYY-MM-DD"),
    moment().subtract(1, "days").format("YYYY-MM-DD"),
    moment().format("YYYY-MM"),
  ];
}

export function modifyData(
  data: { [key: string]: any },
  start: string | number | null = null,
  end: string | number | null = null
) {
  if (data === null || data === undefined) return [{} as ArchiveData];

  if (start === null) {
    start = moment(moment().format("YYYY-MM-DD")).unix();
    Object.keys(data).forEach((date) => {
      if (start && start > moment(date, "YYYY-MM-DD").unix()) {
        start = moment(date, "YYYY-MM-DD").unix();
      }
    });
  }
  const ts_start = moment(start, "YYYY-MM-DD").unix(),
    ts_end =
      end !== null
        ? moment(end, "YYYY-MM-DD").unix()
        : moment(moment().format("YYYY-MM-DD")).unix();
  let ts = ts_start;
  const res = [];
  while (ts <= ts_end) {
    let dat = moment.unix(ts).format("YYYY-MM-DD"),
      ans;
    ans = data.hasOwnProperty(dat)
      ? {
          timestamp: moment(dat, "YYYY-MM-DD").format("DD-MM-YYYY"),
          unitsProduced: data[dat],
        }
      : {
          timestamp: moment(dat, "YYYY-MM-DD").format("DD-MM-YYYY"),
          unitsProduced: 0,
        };
    res.push(ans as ArchiveData);
    ts += 86400;
  }
  return res;
}
//trisshan-solar-specific
export const getInverterColor = (inverterStatus: number) => {
  const _val = inverterStatus.toString(2).split("").reverse();
  for (let i = _val.length; i <= 15; i++) _val[i] = "0";
  if (_val[1] === "1") return "#ff0000";
  if (_val[2] === "1") return "#fffb01";
  if (_val[5] === "1") return "#389c38";
  return "white";
};

export function useDatabaseQueryValue<T = unknown | null, R = T>(
  key: QueryKey,
  ref: Query,
  options: UseDatabaseValueOptions = {},
  useQueryOptions?: Omit<UseQueryOptions<T, Error, R>, "queryFn">
): UseQueryResult<R, Error> {
  const client = useQueryClient();
  const unsubscribe = useRef<Unsubscribe>();

  useEffect(() => {
    return () => {
      unsubscribe.current?.();
    };
  }, []);

  return useQuery<T, Error, R>({
    ...useQueryOptions,
    queryKey: useQueryOptions?.queryKey ?? key,
    staleTime:
      useQueryOptions?.staleTime ?? options?.subscribe ? Infinity : undefined,
    async queryFn() {
      unsubscribe.current?.();
      let resolved = false;

      return new Promise<T>((resolve, reject) => {
        unsubscribe.current = onValue(
          ref,
          (snapshot) => {
            if (!resolved) {
              resolved = true;
              return resolve(parseDataSnapshot(snapshot, !!options?.toArray));
            } else {
              client.setQueryData<T>(
                key,
                parseDataSnapshot(snapshot, !!options?.toArray)
              );
            }
          },
          reject,
          {
            onlyOnce: !options.subscribe,
          }
        );
      });
    },
  });
}

function parseDataSnapshot(snapshot: DataSnapshot, toArray: boolean): any {
  if (!snapshot.exists()) {
    return null;
  }

  if (snapshot.hasChildren() && toArray) {
    const array: unknown[] = [];
    snapshot.forEach((snapshot) => {
      array.push(parseDataSnapshot(snapshot, toArray));
    });
    return array;
  }

  return snapshot.val();
}

export function convertTimestampToTime(timestamp: number) {
  const date = new Date(timestamp * 1000);
  let hr = new Intl.DateTimeFormat("en", {
    hour: "2-digit",
  }).format(date);
  let min = new Intl.DateTimeFormat("en", {
    minute: "2-digit",
  }).format(date);
  const hour = hr.split(" ");
  return `${hour[0]}:${min} ${hour[1]}`;
}

export function modData(data: Array<{ [key: string]: any }>) {
  return data.map((val) => {
    let returntype: { [key: string]: any } = {
      timestamp: convertTimestampToTime(val.timestamp),
      unitsProduced: val.unitsProduced,
      ...val.data,
    };
    // Object.keys(val.data).forEach((key) => {
    //   returntype[key] = val.data[key];
    // });
    return returntype as TimestampData;
  });
}
