import React, { useEffect, useState } from "react";
import "./Navigator.scss";
import { AiOutlineHome } from "react-icons/ai";
import logo from "../../img/trisshanth-logo.jpg";
import {
  MdOutlineAnalytics,
  MdOutlineDashboard,
  MdAddAlert,
} from "react-icons/md";
import { /* FiUsers, */ FiLogOut } from "react-icons/fi";
import { SidebarItems, SidebarProps } from "./Sidebar";
import { getAuth } from "firebase/auth";
import { app } from "../../firebase";

export const Navigator: React.FC = () => {
  const auth = getAuth(app);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const unsub = auth.onAuthStateChanged((user) => {
      setLoggedIn(!!user);
    });
    return unsub;
  }, [loggedIn]);

  const navItems: SidebarProps[] = [
    {
      className: "logo",
      icon: <img src={logo} alt="Espen logo" />,
      path: "/",
    },
    {
      className: "nav-item",
      icon: <AiOutlineHome />,
      linkText: "Home",
      path: "/",
    },
    {
      className: "nav-item",
      icon: <MdOutlineDashboard />,
      linkText: "Dashboard",
      path: "/dashboard",
    },
    // {
    //   className: 'nav-item',
    //   icon: <FiUsers />,
    //   linkText: 'Employees',
    //   path: '/employees',
    // },
    {
      className: "nav-item",
      icon: <MdOutlineAnalytics />,
      linkText: "Analytics",
      path: "/analytics",
    },
    // {
    //   className: "nav-item",
    //   icon: <MdAddAlert />,
    //   linkText: "Alerts",
    //   path: "/alert",
    // },
  ];

  return (
    <nav className="navbar">
      <ul className="navbar-nav">
        {navItems.map(({ className, path, icon, linkText }, index) => {
          return (
            <SidebarItems
              key={index}
              className={className}
              path={path}
              icon={icon}
              linkText={linkText}
            />
          );
        })}
        {loggedIn && (
          <SidebarItems
            className="nav-item logout"
            path="/logout"
            icon={<FiLogOut />}
            linkText="Logout"
          />
        )}
      </ul>
    </nav>
  );
};
