import React, { useState } from "react";
import {
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  BarChart,
  Bar,
} from "recharts";
import { ArchiveData, TimestampData } from "../../../models";

export interface PropsType {
  data: ArchiveData[] | TimestampData[];
  chartType: string;
  labels: { [key: string]: any }[];
  type: string;
  dataKey: string;
}

export const Chart: React.FC<PropsType> = ({
  type,
  data: invData,
  labels,
  chartType,
  dataKey,
}) => {
  const props = {
    width: 500,
    height: 400,
    data: invData,
    margin: { top: 20, right: 20, left: 10, bottom: 20 },
  };

  const { data } = props;
  let prevValue = 0;
  const newData = data.map((value, index, array) => {
    prevValue += value.unitsProduced;
    return {
      ...value,
      unitsProduced: prevValue,
    };
  });
  const [lineProps, setLineProps] = useState<{ [key: string]: any }[]>(labels);
  // const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const ChartChildren = (
    <React.Fragment>
      <XAxis
        allowDataOverflow
        dataKey={(data) => data.timestamp}
        scale="band"
      />
      <YAxis
        domain={[0, (dataMax: number) => Math.ceil(dataMax / 100) * 100 + 100]}
        tickCount={10}
      />
      <CartesianGrid strokeDasharray="3 3" />
      <Tooltip
        formatter={(value: any, name: any, props: any) => [
          value > 1000
            ? `${value} units(kW)
            ${(value / 1000).toFixed(2)} mW`
            : `${value} units(kW)`,
          "unitsProduced",
        ]}
      />
      <Legend
        onClick={(data) => {
          const index = lineProps.findIndex((val) => {
            return val.name === data.value;
          });
          setLineProps((prev) => {
            const newProps = [...prev];
            newProps[index].hidden = !newProps[index].hidden;
            return newProps;
          });
        }}
      />
    </React.Fragment>
  );

  let chart;
  switch (chartType) {
    case "bar": {
      chart = (
        <BarChart {...props}>
          {ChartChildren}
          {lineProps.map((label: any, index: number) => {
            return (
              <Bar
                key={index}
                name={label.name}
                dataKey={dataKey}
                fill={label.color}
                hide={label.hidden}
                legendType="wye"
              />
            );
          })}
        </BarChart>
      );
      break;
    }

    case "cum(bar)": {
      chart = (
        <BarChart {...props} data={newData}>
          {ChartChildren}
          {lineProps.map((label: any, index: number) => {
            return (
              <Bar
                key={index}
                name={label.name}
                dataKey={dataKey}
                fill={label.color}
                hide={label.hidden}
                legendType="wye"
              />
            );
          })}
        </BarChart>
      );
      break;
    }
    case "cum(area)": {
      chart = (
        <AreaChart {...props} data={newData}>
          {ChartChildren}
          {lineProps.map((label: any, index: number) => {
            return (
              <Area
                key={index}
                name={label.name}
                type="monotone"
                /* dataKey={(data) => {
                  return data[`totalProduction-${label.name}`]
                }} */
                dataKey={(d) => d.unitsProduced}
                stroke={label.color}
                fill={label.color}
                hide={label.hidden}
                legendType="wye"
                connectNulls
                activeDot={{
                  strokeWidth: 2,
                  r: 6,
                  // onClick: (e, pay: { [key: string]: any }) =>
                  // setActiveIndex(pay.index),
                }}
              />
            );
          })}
        </AreaChart>
      );
      break;
    }

    default: {
      chart = (
        <AreaChart {...props}>
          {ChartChildren}
          {lineProps.map((label: any, index: number) => {
            return (
              <Area
                key={index}
                name={label.name}
                type="monotone"
                /* dataKey={(data) => {
                  return data[`totalProduction-${label.name}`]
                }} */
                dataKey={(d) => d.unitsProduced}
                stroke={label.color}
                hide={label.hidden}
                fill={label.color}
                legendType="wye"
                connectNulls
                activeDot={{
                  strokeWidth: 2,
                  r: 6,
                  // onClick: (e, pay: { [key: string]: any }) =>
                  // setActiveIndex(pay.index),
                }}
              />
            );
          })}
        </AreaChart>
      );
      break;
    }
  }

  return (
    <React.Fragment>
      {/* {activeIndex && <FloatingChart onClick={() => setActiveIndex(null)} />} */}
      <ResponsiveContainer width="100%" height="100%">
        {chart}
      </ResponsiveContainer>
    </React.Fragment>
  );
};
