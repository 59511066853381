import { Timestamp } from "firebase/firestore";
import React from "react";
import { ArchiveData, TimestampData } from "../../models";
import "./AnalysisTable.scss";

export const AnalysisTable = ({
  data,
}: {
  data: ArchiveData[] | TimestampData[];
}) => {
  const keys = Object.keys(data[0]);
  const header = keys.map((v, i) => <th key={i}>{v}</th>);
  const numberOfRows = 20;
  const paginationCount = data.length / numberOfRows + 1;
  const [body, setBody] = React.useState([<></>]);
  const [curPage, setCurPage] = React.useState(0);

  // console.log(JSON.stringify(data.map((val: ArchiveData | TimestampData, index: number) => {
  //   return (index > 5 || index <= 18) ? val : {};
  // })))

  const page = (n: number) => {
    let _data: any[] = data;
    const _temp: TimestampData[] | ArchiveData[] = _data.filter(
      (_, i: number) =>
        i >= n * numberOfRows && i < n * numberOfRows + numberOfRows
    );
    setBody(() =>
      _temp.map((v: any, i: number) =>
        <tr key={i}>
          <td>{i + curPage * numberOfRows + 1}</td>
          {keys.map((str, l) => (
            <td key={i + ' ' + l}>{v[str]}</td>
          ))}
        </tr>
      )
    );

  };
  React.useEffect(() => {
    page(curPage);
  }, [curPage]);
  React.useEffect(() => {
    page(0);
  }, [data]);
  if (keys.length === 0)
    return <></>
  return (
    <>
      <div className="analysis-table-holder">
        <table className="analysis-table">
          <thead className="analysis-table-thead">
            <tr>
              <th>SI.NO</th>
              {header}
            </tr>
          </thead>
          <tbody>{body.map((v, i) => <React.Fragment key={i}>{v}</React.Fragment>)}</tbody>
        </table>
      </div>
      <ul className="pagination-btns">
        <li>
          <div>Pages: </div>
        </li>
        {Array.from({ length: paginationCount }, (_, i) => (
          <li key={i}>
            <div
              onClick={() => {
                setCurPage(i);
              }}
            >
              {i + 1}
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};
